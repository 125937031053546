import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputBase,
  Radio,
  RadioGroup,
  Rating,
  SelectChangeEvent,
  Stack,
  Typography
} from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import {
  FeedbackIcon,
  FullScreenLoader,
  PromotionsIcon
} from '../../../components';
import {
  useFeedbackListQuery,
  useHotelDetailsLazyQuery,
  usePropertyDetailsQuery,
  useSubmitFeedbackMutation
} from '../../../generated/graphql';
import { CardPaper } from './CardPaper';
import { useAuth } from '../../../store';
import { useDomain, useSweetAlert } from '../../../utils';
import Swal from 'sweetalert2';

interface Props {
  hideFeedback?: boolean;
  hidePromotionTile?: boolean;
}

const FeedbackAndPromotions = ({ hideFeedback, hidePromotionTile }: Props) => {
  const sweetAlert = useSweetAlert();
  const guest = useAuth((state: any) => state.guest);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState('');
  const [feedBackType, setFeedBackType] = React.useState<any>();
  const [feedback, setFeedback] = React.useState('1');
  const [ratings, setRatings] = React.useState<number | null>(0);
  const { data: feedbackList, loading: loadingFeedbackList } =
    useFeedbackListQuery({
      variables: {
        propertyID: guest?.property_id
      }
    });
  const [createFeedback, { loading: loadingSubmitFeedback }] =
    useSubmitFeedbackMutation();

  const [hotelDetailsQuery, { data: propertyMeta, loading: loadingMeta }] =
    useHotelDetailsLazyQuery();

  const domainId = useDomain();
  const { data: propertyDetails, loading: loadingProperty } =
    usePropertyDetailsQuery({
      variables: {
        domain: domainId as string
      }
    });

  React.useEffect(() => {
    const propertyID = propertyDetails?.getPropertyByDomain?.id;
    if (!propertyID) return;

    hotelDetailsQuery({
      variables: {
        propertyID
      }
    });
  }, [propertyDetails?.getPropertyByDomain?.id, hotelDetailsQuery]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRatings(0);
    setComment('');
    setFeedBackType('');
  };

  const showConfirmationDialog = async () => {
    return Swal.fire({
      title: 'Agent Request',
      text: 'Do you want to talk our agent for complaint?',
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#808080',
      cancelButtonColor: '#7066e0'
    });
  };

  const onSubmitFeedback = async () => {
    setOpen(false);
    if (feedback === '2') {
      const result = await showConfirmationDialog();
      if (result.isConfirmed) {
        navigate('/chat', { state: { comment } });
        // setOpenDialog(true);
        handleClose();
      } else {
        try {
          const res = await createFeedback({
            variables: {
              input: {
                property_id: guest?.property_id,
                room_id: guest?.room_id,
                feedback_type_id: feedBackType?.id,
                feedback: comment
              }
            }
          });
          if (res.data?.createFeedback?.code === '200') {
            sweetAlert.fire({
              text: 'Complaint Submitted Successfully',
              icon: 'success',
              timer: 1000
            });
          } else {
            sweetAlert.fire({
              text: res.data?.createFeedback?.message,
              icon: 'error'
            });
          }
          handleClose();
        } catch (error) {
          sweetAlert.fire({
            text: 'Something went wrong',
            icon: 'error'
          });
        }
      }
    } else {
      try {
        const res = await createFeedback({
          variables: {
            input: {
              property_id: guest?.property_id,
              room_id: guest?.room_id,
              feedback_type_id: feedBackType?.id,
              feedback: comment
            }
          }
        });
        if (res.data?.createFeedback?.code === '200') {
          sweetAlert.fire({
            text: 'Feedback Submitted Successfully',
            icon: 'success',
            timer: 1000
          });
        } else {
          sweetAlert.fire({
            text: res.data?.createFeedback?.message,
            icon: 'error'
          });
        }
        handleClose();
      } catch (error) {
        sweetAlert.fire({
          text: 'Something went wrong',
          icon: 'error'
        });
      }
    }
  };

  React.useEffect(() => {
    if (!feedBackType && feedbackList) {
      setFeedBackType(
        feedbackList?.getFeedbackTypeList &&
          feedbackList?.getFeedbackTypeList[0]
      );
    }
  }, [feedBackType, feedbackList?.getFeedbackTypeList, feedbackList]);

  if (
    loadingFeedbackList ||
    loadingSubmitFeedback ||
    loadingProperty ||
    loadingMeta
  ) {
    return <FullScreenLoader />;
  }
  const handleChange = (event: SelectChangeEvent) => {
    setFeedback(event.target.value);
  };

  return (
    <Box sx={{ mt: 3, display: 'flex' }}>
      {!Boolean(hidePromotionTile) && (
        <CardPaper
          sx={{ p: 1.5, flex: 1 }}
          onClick={() => navigate('/promotions')}
        >
          <PromotionsIcon color="primary" sx={{ height: 60, width: 60 }} />
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            Promotions
          </Typography>
          {propertyMeta?.getMeta?.promotion_desc?.toString().startsWith('<') ? (
            <div
              contentEditable="false"
              dangerouslySetInnerHTML={{
                __html: propertyMeta?.getMeta?.promotion_desc?.toString() || ''
              }}
            ></div>
          ) : (
            <Typography sx={{ color: 'text.secondary' }}>
              {propertyMeta?.getMeta?.promotion_desc}
            </Typography>
          )}
        </CardPaper>
      )}

      {!Boolean(hideFeedback) && (
        <CardPaper sx={{ p: 1.5, flex: 1, ml: 1.5 }} onClick={handleClickOpen}>
          <FeedbackIcon color="primary" sx={{ height: 60, width: 60 }} />
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            Leave Feedback
          </Typography>

          {propertyMeta?.getMeta?.feedback_desc?.toString().startsWith('<') ? (
            <div
              contentEditable="false"
              dangerouslySetInnerHTML={{
                __html: propertyMeta?.getMeta?.feedback_desc?.toString() || ''
              }}
            ></div>
          ) : (
            <Typography sx={{ color: 'text.secondary' }}>
              {propertyMeta?.getMeta?.feedback_desc}
            </Typography>
          )}

          {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            <div
              dangerouslySetInnerHTML={{
                __html: propertyMeta?.getMeta?.feedback_desc?.toString() || ''
              }}
            ></div>
          </Typography> */}
        </CardPaper>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            overflow: 'hidden',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::-webkit-scrollbar': { display: 'none' }
          }}
        >
          <FeedbackIcon color="primary" sx={{ height: 60, width: 60 }} />
          <Typography variant="h6" sx={{ my: 1 }}>
            Leave Feedback
          </Typography>

          {/* <DropDown
            extractId={option => option.id}
            onClick={setFeedBackType}
            extractLabel={option => option?.name}
            options={feedbackList?.getFeedbackTypeList || []}
            selectedOption={feedBackType}
          /> */}
          <FormControl
            component="fieldset"
            sx={{ m: 0, minWidth: '100%' }}
            size="small"
          >
            <RadioGroup
              aria-label="feedback-type"
              name="feedback_type_id"
              value={feedback}
              onChange={handleChange}
            >
              <Stack direction="row" spacing={1}>
                {feedbackList?.getFeedbackTypeList?.map((feedback: any) => (
                  <FormControlLabel
                    key={feedback.id}
                    value={feedback.id.toString()} // Make sure to convert the value to a string
                    control={<Radio size="small" />}
                    label={feedback.name}
                  />
                ))}
              </Stack>
            </RadioGroup>
          </FormControl>

          <Rating
            size="large"
            value={ratings}
            onChange={(event, newValue) => {
              setRatings(newValue);
            }}
            icon={
              <StarIcon fontSize="inherit" sx={{ color: 'primary.main' }} />
            }
            emptyIcon={
              <StarBorderIcon
                fontSize="inherit"
                sx={{ color: 'primary.main' }}
              />
            }
            sx={{ mb: 2 }}
          />
          <InputBase
            rows={3}
            multiline
            value={comment}
            placeholder="Write your comment here"
            onChange={e => setComment(e.target.value)}
            sx={{
              p: 1.5,
              width: 212,
              borderRadius: 1.25,
              border: '1px solid #C5C5C5'
            }}
          />
        </DialogContent>
        <DialogActions sx={{ px: 2, justifyContent: 'stretch' }}>
          <Button onClick={handleClose} sx={{ flex: 1 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onSubmitFeedback}
            autoFocus
            sx={{ flex: 1 }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export { FeedbackAndPromotions };
